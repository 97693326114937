import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Carousel from 'react-material-ui-carousel';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { Box, List, ListItem, ListItemText, Button, TextField } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import axios from 'axios';

const SearchDriverResultCard = ({ user, onClose }) => {
    const { id, phone_number, email, username } = user;
    const [entregas, setEntregas] = useState([]);
    const [recojos, setRecojos] = useState([]);
    const [driver, setDriver] = useState(null);
    const [selectedDelivery, setSelectedDelivery] = useState(null);
    const [selectedDate, setSelectedDate] = useState('');
    const [total_deposit, setTotalDeposit] = useState(0);
    const [images, setImages] = useState([]);
    console.log('selectedDelivery:', selectedDelivery);

    useEffect(() => {
        if (selectedDate) {
            fetchDeliveries();
        }
    }, [selectedDate]);

    useEffect(() => {
        const fetchDriver = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/location/driver`, {
                    params: {
                      driver_id: id, // Send the driver_id as a query parameter
                    },
                });
                setDriver(response.data);  // Correctly set the fetched driver data
                console.log('Fetched driver:', response.data);
                console.log('Driver:', driver);  // This will still be the previous state because state updates are asynchronous
            } catch (error) {
                console.error('Error fetching driver:', error);
            }
        };
    
        if (id) {
            fetchDriver();  // Call the async function to fetch data
        }
    }, [id]);

    const handlePickupRoutes = async () => {
        if (!driver) {
            console.error('Driver not found');
            return;
        }
    
        // Define the start point (driver's location) and the fixed end point
        const startPoint = {
            lat: driver.latitude,
            lng: driver.longitude
        };
        const endPoint = {
            lat: -12.00093305507352,
            lng: -77.05374889286432
        };
    
        // Extract waypoints (pickup locations) from the recojos array
        const waypoints = recojos
            .filter(point => point.driver_id === id)
            .map(point => ({
                lat: point.address.latitude,
                lng: point.address.longitude
            }));
    
        // Collect order IDs for sending to the backend
        const orderIds = recojos
            .filter(point => point.driver_id === id)
            .map(point => point.id);
    
        console.log('Driver location:', startPoint);
        console.log('End point:', endPoint);
        console.log('Waypoints:', waypoints);
        console.log('Order IDs:', orderIds);
    
        // Call the backend to create the optimized route using Google Maps API
        try {
            const token = localStorage.getItem("token");
    
            // Call the backend route directly
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/create_route_optimized`,
                {
                    startPoint: `${startPoint.lat},${startPoint.lng}`,
                    endPoint: `${endPoint.lat},${endPoint.lng}`,
                    waypoints: waypoints.map(wp => `${wp.lat},${wp.lng}`).join('|'),
                    orderIds: orderIds
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            
    
            console.log('Full response:', response.data);
            alert('Route optimized and updated successfully!');
        } catch (error) {
            console.error('Error creating optimized route:', error);
            alert('Failed to optimize route. Please try again.');
        }
    };

    const handlePickupRoutesSequence = async () => {
        if (!driver) {
            console.error('Driver not found');
            return;
        }
    
        // Define the start point (driver's current location)
        const startPoint = {
            lat: driver.latitude,
            lng: driver.longitude,
        };
    
        // Define the fixed end point
        const endPoint = {
            lat: -12.00093305507352,
            lng: -77.05374889286432,
        };
    
        // Filter and sort the recojos array by sequence
        const sortedRecojos = recojos
            .filter(point => point.driver_id === id && point.status === 'pending')
            .sort((a, b) => a.sequence - b.sequence);
    
        // Extract waypoints and order IDs from the sorted recojos
        const waypoints = sortedRecojos.map(point => ({
            lat: point.address.latitude,
            lng: point.address.longitude,
        }));
        const orderIds = sortedRecojos.map(point => point.id);
    
        console.log('Driver location:', startPoint);
        console.log('End point:', endPoint);
        console.log('Waypoints (sorted by sequence):', waypoints);
        console.log('Order IDs (sorted by sequence):', orderIds);
    
        // Call the backend to create the route using the sorted waypoints
        try {
            const token = localStorage.getItem("token");
    
            // Call the backend route directly
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/create_route`,
                {
                    startPoint: `${startPoint.lat},${startPoint.lng}`,
                    endPoint: `${endPoint.lat},${endPoint.lng}`,
                    waypoints: waypoints.map(wp => `${wp.lat},${wp.lng}`).join('|'),
                    orderIds: orderIds,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            // Check the response from the backend
            if (response.data.message === 'Arrival times updated successfully') {
                console.log('Full response:', response.data);
                alert('Route created successfully and arrival times updated!');
            } else {
                console.error('Backend response error:', response.data);
                alert('Failed to create route. Please try again.');
            }
        } catch (error) {
            console.error('Error creating route:', error);
            alert('Failed to create route. Please try again.');
        }
    };

    const handleEntregasRoutes = async () => {
        if (!driver) {
            console.error('Driver not found');
            return;
        }
    
        // Define the fixed start point
        const startPoint = {
            lat: driver.latitude,
            lng: driver.longitude,
        };
    
        // Define the end point (driver's current location)
        const endPoint = {
            lat: driver.final_latitude,
            lng: driver.final_longitude,
        };
    
        // Filter and sort the entregas array by sequence
        const sortedEntregas = entregas
            .filter(point => point.driver_id === id && point.status === 'pending')
            .sort((a, b) => a.sequence - b.sequence);
    
        // Extract waypoints and delivery IDs from the sorted entregas
        const waypoints = sortedEntregas.map(point => ({
            lat: point.latitude,
            lng: point.longitude,
        }));
        const deliveryIds = sortedEntregas.map(point => point.id);
    
        console.log('Start point:', startPoint);
        console.log('End point:', endPoint);
        console.log('Waypoints (sorted by sequence):', waypoints);
        console.log('Delivery IDs (sorted by sequence):', deliveryIds);
    
        // Call the backend to create the route using the sorted waypoints
        try {
            const token = localStorage.getItem("token");
    
            // Call the backend route directly
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/create_route_deliveries`,
                {
                    startPoint: `${startPoint.lat},${startPoint.lng}`,
                    endPoint: `${endPoint.lat},${endPoint.lng}`,
                    waypoints: waypoints.map(wp => `${wp.lat},${wp.lng}`).join('|'),
                    deliveryIds: deliveryIds,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            // Check the response from the backend
            if (response.data.message === 'Arrival times updated successfully') {
                console.log('Full response:', response.data);
                alert('Route created successfully and arrival times updated!');
            } else {
                console.error('Backend response error:', response.data);
                alert('Failed to create route. Please try again.');
            }
        } catch (error) {
            console.error('Error creating route:', error);
            alert('Failed to create route. Please try again.');
        }
    };
    

    const handleEntregasRoutesSequence = async () => {
        if (!driver) {
            console.error('Driver not found');
            return;
        }
    
        // Define the fixed start point (Lima Centro) and the driver's location as the end point
        const startPoint = {
            lat: driver.latitude,
            lng: driver.longitude,
        };
        const endPoint = {
            lat: driver.final_latitude,
            lng: driver.final_longitude,
        };
    
        // Filter and extract waypoints and delivery IDs from the entregas array
        const waypoints = entregas
            .filter(point => point.status === 'pending' && point.latitude && point.longitude)
            .map(point => ({
                lat: point.latitude,
                lng: point.longitude,
            }));
        
        const deliveryIds = entregas
            .filter(point => point.status === 'pending' && point.latitude && point.longitude)
            .map(point => point.id);
    
        console.log('Start point:', startPoint);
        console.log('End point:', endPoint);
        console.log('Waypoints:', waypoints);
        console.log('Delivery IDs:', deliveryIds);
    
        // Call the backend to create the optimized route using Google Maps API
        try {
            const token = localStorage.getItem("token");
    
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/create_route_deliveries_optimized`,
                {
                    startPoint: `${startPoint.lat},${startPoint.lng}`,
                    endPoint: `${endPoint.lat},${endPoint.lng}`,
                    waypoints: waypoints.map(wp => `${wp.lat},${wp.lng}`).join('|'),
                    deliveryIds: deliveryIds,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            if (response.data.message === 'Optimized route created successfully and arrival times updated') {
                console.log('Full response:', response.data);
                alert('Optimized route created successfully and arrival times updated!');
            } else {
                console.error('Backend response error:', response.data);
                alert('Failed to create optimized route. Please try again.');
            }
        } catch (error) {
            console.error('Error creating optimized route:', error);
            alert('Failed to create optimized route. Please try again.');
        }
    };


    const handleEncaminoClick = async () => {
        // Find all delivery points with status 'pending'
        const pendingEntregas = entregas.filter(entrega => entrega.status === 'pending');
        
        // Extract their IDs
        const pendingIds = pendingEntregas.map(entrega => entrega.id);
    
        if (pendingIds.length === 0) {
            console.log('No pending deliveries to update.');
            return;
        }
    
        try {
            // Send the pending delivery point IDs to the backend for update
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/update_delivery_status`, {
                ids: pendingIds,  // Send the array of IDs to the backend
                status: 'encamino'
            });
            
            console.log('Updated delivery points:', response.data);
            // Optionally update local state after successful response
            setEntregas(entregas.map(entrega => 
                pendingIds.includes(entrega.id) ? { ...entrega, status: 'encamino' } : entrega
            ));
        } catch (error) {
            console.error('Error updating delivery points:', error);
        }
    };

    const handleOrderEncaminoClick = async () => {
        // Find all delivery points with status 'pending'
        const pendingRecojos = recojos.filter(recojo => recojo.status === 'pending');
        
        // Extract their IDs
        const pendingIds = pendingRecojos.map(recojo => recojo.id);
    
        if (pendingIds.length === 0) {
            console.log('No pending orders to update.');
            return;
        }
    
        try {
            // Send the pending delivery point IDs to the backend for update
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/update_order_status`, {
                ids: pendingIds,  // Send the array of IDs to the backend
                status: 'encamino'
            });
            
            console.log('Updated order points:', response.data);
            // Optionally update local state after successful response
            setRecojos(recojos.map(recojo => 
                pendingIds.includes(recojo.id) ? { ...recojo, status: 'encamino' } : recojo
            ));
        } catch (error) {
            console.error('Error updating recojo points:', error);
        }
    };

    const handlePendingClick = async () => {
        // Find all delivery points with status 'pending'
        const encaminoEntregas = entregas.filter(entrega => entrega.status === 'encamino');
        
        // Extract their IDs
        const encaminoIds = encaminoEntregas.map(entrega => entrega.id);
    
        if (encaminoIds.length === 0) {
            console.log('No encamino deliveries to update.');
            return;
        }
    
        try {
            // Send the pending delivery point IDs to the backend for update
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/update_delivery_status`, {
                ids: encaminoIds,  // Send the array of IDs to the backend
                status: 'pending'
            });
            
            console.log('Updated delivery points:', response.data);
            // Optionally update local state after successful response
            setEntregas(entregas.map(entrega => 
                encaminoIds.includes(entrega.id) ? { ...entrega, status: 'pending' } : entrega
            ));
        } catch (error) {
            console.error('Error updating delivery points:', error);
        }
    };

    const handleOrderPendingClick = async () => {
        // Find all delivery points with status 'pending'
        const encaminoRecojos = recojos.filter(recojo => recojo.status === 'encamino');
        
        // Extract their IDs
        const encaminoIds = encaminoRecojos.map(recojo => recojo.id);
    
        if (encaminoIds.length === 0) {
            console.log('No encamino orders to update.');
            return;
        }
    
        try {
            // Send the pending delivery point IDs to the backend for update
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/update_order_status`, {
                ids: encaminoIds,  // Send the array of IDs to the backend
                status: 'pending'
            });
            
            console.log('Updated order points:', response.data);
            // Optionally update local state after successful response
            setRecojos(recojos.map(recojo => 
                encaminoIds.includes(recojo.id) ? { ...recojo, status: 'pending' } : recojo
            ));
        } catch (error) {
            console.error('Error updating delivery points:', error);
        }
    };


    useEffect(() => {
        const calculateTotalDeposit = () => {
            const total = entregas.reduce((sum, entrega) => {
                // Add only if transfer is false and charged is a valid number
                return entrega.transfer === false ? sum + (entrega.charged || 0) : sum;
            }, 0);
            setTotalDeposit(total);  // Update the total deposit state
        };
    
        calculateTotalDeposit();  // Call the function to calculate the total
    }, [entregas]);

    const fetchDeliveries = async () => {
        try {

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/driver_deliveries`, {
                params: {
                    driver_id: id,
                    date: selectedDate
                },
            });

            setEntregas(response.data.entregas);
            console.log('Entregas:', response.data.entregas);
            setRecojos(response.data.recojos);
            console.log('Recojos:', response.data.recojos);
        } catch (error) {
            console.error('Error fetching deliveries:', error);
        }
    };

    const handleWhatsAppClick = () => {
        window.open(`https://wa.me/51${phone_number}`, '_blank');
    };

    const handleDeliveryClick = (delivery) => {
        setSelectedDelivery(delivery);
        setImages(delivery.image_urls || []);  // Assuming delivery.image_urls is an array of image URLs
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Card sx={{ maxWidth: 1000, height: 650,  position: 'relative', top: "80px", left: "10px", zIndex: '2000' }}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {username} {id} {phone_number}
                    </Typography>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body2" color="text.secondary">
                             {email}
                        </Typography>
                        <Box>
                            <IconButton size="small" onClick={handleWhatsAppClick}>
                                <WhatsAppIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handlePickupRoutes}>
                                <AddBusinessIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handlePickupRoutesSequence}>
                                <HourglassTopIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleOrderPendingClick}>
                                <PauseIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleOrderEncaminoClick}>
                                <PlayArrowIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body2" color="text.secondary">
                              {total_deposit}
                        </Typography>
                        <Box>
                            <IconButton size="small" >
                                <LocalGroceryStoreIcon onClick={handleEntregasRoutesSequence}/>
                            </IconButton>
                            <IconButton size="small" onClick={handleEntregasRoutes}>
                                <HourglassTopIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handlePendingClick}>
                                <PauseIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleEncaminoClick}>
                                <PlayArrowIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    
                    <Box sx={{ marginTop: 2 }}>
                        <TextField
                            label="Pick Date"
                            type="date"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                </CardContent>
                
                <Box sx={{ display: 'flex', flexDirection: 'row', height: '420px', marginTop: 2 }}>
                    <Box sx={{ width: '30%', maxHeight: '400px', overflowY: 'auto' }}>
                        <Typography variant="h6">Lista de Recojos</Typography>
                        <List>
                            {recojos.map((recojo) => (
                                <ListItem button key={recojo.id} onClick={() => handleDeliveryClick(recojo)}>
                                    <ListItemText primary={`ID: ${recojo.id} - ${recojo.status} - ${recojo.sequence}`} />
                                </ListItem>
                            ))}
                        </List>
                        <Typography variant="h6">Lista de Entregas</Typography>
                        <List>
                            {entregas.map((entrega) => (
                                <ListItem button key={entrega.id} onClick={() => handleDeliveryClick(entrega)}>
                                    <ListItemText primary={`ID: ${entrega.id} - ${entrega.status} - ${entrega.sequence}`} />
                                    
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <Box sx={{ width: '35%', height: '100%' }}>
                        <Typography variant="h6">Detalles</Typography>
                        {selectedDelivery ? (
                            <Box>
                                <Typography variant="body2" color="text.secondary">
                                    ID: {selectedDelivery.id}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Estado: {selectedDelivery.status}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    User ID: {selectedDelivery.user_id}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Nombre: {selectedDelivery.recipient_name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Cobrar: {selectedDelivery.charge}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Transferencia FM: {selectedDelivery.transfer.toString()}
                                </Typography>
                                
                                <Typography variant="body2" color="text.secondary">
                                    Cobrado: {selectedDelivery.charged}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Notas: {selectedDelivery.notes}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Notas Driver: {selectedDelivery.notes_driver}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Distrito: {selectedDelivery.district}
                                </Typography>
                            </Box>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No delivery selected.
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ width: '35%', height: '100%' }}>
                        <Typography variant="h6">Imagenes</Typography>
                        {images.length > 0 ? (
                            <Carousel>
                                {images.map((image, index) => (
                                    <img
                                        key={index}
                                        src={image}
                                        alt={`Delivery Image ${index + 1}`}
                                        style={{ width: '100%', height: '350px', objectFit: 'cover' }}
                                    />
                                ))}
                            </Carousel>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No images disponibles.
                            </Typography>
                        )}
                    </Box>
                </Box>

                <IconButton
                    size="medium"
                    onClick={onClose}
                    style={{ position: 'absolute', top: 7, right: 7, backgroundColor: 'white', zIndex: 1 }}
                >
                    <CloseIcon />
                </IconButton>
            </Card>
        </Box>
    );
};

export default SearchDriverResultCard;
