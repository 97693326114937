import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { TextField, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import MyLocationIcon from '@mui/icons-material/MyLocation';

function MapSelectionDriver({ onBackClick, userId }) {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedLocation, setSelectedLocation] = useState(null);
    const currentMarkerRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const ConfirmModal = ({ message, onConfirm, onCancel }) => (
      <div className="modal-overlay-entrega">
        <div 
          className="modal-content"
          style={{
            backgroundColor: 'white',   // Set modal background to white
            padding: '20px',            // Add padding for a clean look
            borderRadius: '8px',        // Optional: Add border radius for smooth corners
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Optional: Add shadow for depth
          }}
        >
          <div 
            className="modal-message"
            style={{
              color: 'black',          // Make font color black
              fontWeight: 'bold',      // Make text a bit bolder
              fontSize: '16px',        // Optional: Adjust font size if needed
              marginBottom: '20px'     // Optional: Add space below the message
            }}
          >
            {message}
          </div>
          <div className="modal-actions" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button
              className="modal-ok-button"
              onClick={onConfirm}
              style={{
                backgroundColor: 'rgb(255, 43, 133)',  // Optional: Set a color for the button
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              OK
            </button>
            <button
              className="modal-cancel-button"
              onClick={onCancel}
              style={{
                backgroundColor: 'grey', // Optional: Color for the cancel button
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
    

    useEffect(() => {
        if (mapContainerRef.current && window.google) {
            let mapCenter = { lat: -12.060235303763926, lng: -77.03711992646711 };
            let zoomLevel = 12;

            // Initialize Google Map
            mapRef.current = new window.google.maps.Map(mapContainerRef.current, {
                center: mapCenter,
                zoom: zoomLevel,
                disableDefaultUI: true,
            });

            mapRef.current.addListener('click', (e) => {
                const { latLng } = e;
                const location = {
                    lat: latLng.lat(),
                    lng: latLng.lng(),
                };
                addMarker(location);
                setSelectedLocation(location);
            });

            alert("Escoge una ubicación precisa de recojo");
        }

        return () => {
            if (mapRef.current) {
                mapRef.current = null; // Cleanup reference
            }
        };
    }, [userId]);

    const addMarker = (location) => {
        if (currentMarkerRef.current) {
            currentMarkerRef.current.setMap(null);
        }

        // Place a new marker on the map
        currentMarkerRef.current = new window.google.maps.Marker({
            position: location,
            map: mapRef.current,
            icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                fillColor: 'red',
                fillOpacity: 1,
                scale: 10,
                strokeColor: 'black',
                strokeWeight: 1,
            },
        });
    };

    const fetchAutocompleteSuggestions = async (input) => {
        if (input.trim().length === 0) {
            setSearchResults([]);
            return;
        }

        const autocompleteService = new window.google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions(
            {
                input,
                componentRestrictions: { country: 'pe' },
                location: new window.google.maps.LatLng(-12.0464, -77.0428), // Center around Lima
                radius: 50000, // Limit to approximately 50 km around Lima
            },
            (predictions, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
                    setSearchResults(predictions);
                } else {
                    setSearchResults([]);
                }
            }
        );
    };

    const handleSearchChange = (event) => {
        const input = event.target.value;
        setSearchQuery(input);
        fetchAutocompleteSuggestions(input);
    };

    const handleResultClick = (result) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ placeId: result.place_id }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
                const location = {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                };
                setSelectedLocation(location);
                addMarker(location);
                mapRef.current.panTo(location);
                mapRef.current.setZoom(17);
            }
        });
        setSearchQuery(result.description);
        setSearchResults([]);
    };

    const handleGetCurrentLocations = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const location = { lat: latitude, lng: longitude };
                    setSelectedLocation(location);
                    addMarker(location);
                    mapRef.current.panTo(location);
                    mapRef.current.setZoom(16);
                },
                (error) => {
                    console.error('Error getting current location:', error);
                }
            );
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    };

    const handleConfirmClick = () => {
        if (!selectedLocation) {
            setErrorMessage("Todos los campos obligatorios deben completarse.");
            return;
        }

        setErrorMessage("");
        setIsModalOpen(true);
    };

    const handleSave = async () => {
        setIsModalOpen(false);

        if (selectedLocation) {
            try {
                const newAddress = {
                    latitude: selectedLocation.lat,
                    longitude: selectedLocation.lng,
                };

                await axios.put(`${process.env.REACT_APP_API_URL}/api/driver_final_location`, {
                    addresses: [newAddress],
                    driver_id: userId,
                });

                onBackClick();
            } catch (error) {
                console.error('Error saving location:', error);
            }
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div style={{ height: "100vh", position: "relative" }}>
            <div ref={mapContainerRef} style={{ height: "60vh" }}></div>

            <div className="search-container-user">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Busca tu dirección"
                    className="search-input"
                />
                {searchResults.length > 0 && (
                    <div className="search-results">
                        {searchResults.map((result) => (
                            <div
                                key={result.place_id}
                                onClick={() => handleResultClick(result)}
                                className="search-result"
                            >
                                {result.description}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <IconButton
                style={{
                    position: "absolute",
                    bottom: "50%",
                    right: 20,
                    zIndex: 1000,
                    backgroundColor: "white",
                    border: "1px solid black",
                    borderRadius: "50%",
                    color: "black",
                }}
                onClick={handleGetCurrentLocations}
            >
                <MyLocationIcon />
            </IconButton>
            <IconButton
                style={{
                    position: "absolute",
                    bottom: "91%",
                    right: 20,
                    zIndex: 1000,
                    backgroundColor: "white",
                    border: "1px solid black",
                    borderRadius: "50%",
                    color: "black",
                }}
                onClick={onBackClick}
            >
                <ArrowBackIcon />
            </IconButton>

            <div
                style={{
                    height: "40vh",
                    padding: "20px",
                    backgroundColor: "#f7f7f7",
                    overflowY: "auto",
                }}
            >
                <div
                    style={{
                        padding: "20px",
                        backgroundColor: "#fff",
                        borderRadius: "8px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        color: "#000",
                    }}
                >
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    <button
                        onClick={handleConfirmClick}
                        style={{
                            display: "block",
                            width: "80%",
                            margin: "0 auto",
                            padding: "10px 20px",
                            backgroundColor: "#616569",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontSize: "16px",
                        }}
                    >
                        Guardar
                    </button>
                    {isModalOpen && (
                      <ConfirmModal
                          message="¿Está seguro de que la ubicacion y direccion ingresada es correcta?"
                          onConfirm={handleSave}
                          onCancel={handleCancel}
                      />
                    )}
                </div>
            </div>
        </div>
    );
}

export default MapSelectionDriver;

