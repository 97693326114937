import React from 'react';


const Modal = ({ message, onClose }) => {
  return (
    <div className="modal-overlay" style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
    }}>
      <div className="modal-content" style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        width: '90%',
        maxWidth: '400px',
        textAlign: 'center',
      }}>
        <div className="modal-message" style={{
          color: 'black',
          fontSize: '16px',
          marginBottom: '20px',
        }}>
          {message}
        </div>
        <button className="modal-close-button" onClick={onClose} style={{
          padding: '10px 20px',
          backgroundColor: '#ff5e5e',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          fontSize: '16px',
        }}>
          OK
        </button>
      </div>
    </div>
  );
};

export default Modal;
