import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import ReactDOMServer from 'react-dom/server';
import MarkerText from './MarkerText';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import StoreIcon from '@mui/icons-material/Store';
import { districts } from './Districs';
import MenuUser from './MenuUser';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Maps = ({ userId, handleEntregaClick, handleRecojoClick, setActiveComponent }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [userMarkers, setUserMarkers] = useState([]);
  const [deliveryPointMarkers, setDeliveryPointMarkers] = useState([]);
  const [driverMarkers, setDriverMarkers] = useState([]);
  const [user, setUser] = useState(null);
  const [deliveryPoints, setDeliveryPoints] = useState([]);
  const [orders, setOrders] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);
  

  useEffect(() => {
    const fetchUserData = () => {
      axios.get(`${process.env.REACT_APP_API_URL}/api/user/${userId}`)
        .then(response => {
          setUser(response.data);
          console.log('user ', response.data);
          setDeliveryPoints(response.data.delivery_points || []);
          setOrders(response.data.order_points || []);
          console.log(response.data.delivery_points);
          console.log(response.data.order_points);
        })
        .catch(error => console.error('Error fetching data:', error));
    };
  
    // Call the function immediately and then set an interval
    fetchUserData();
    const intervalId = setInterval(fetchUserData, 5000); // 5000ms = 5 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
    
  }, [userId]);

  useEffect(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new window.google.maps.Map(mapContainerRef.current, {
        center: { lat: -12.0602, lng: -77.0371 }, // Default center (Lima)
        zoom: 12,
        disableDefaultUI: true,
        gestureHandling: 'greedy',
      });

      // fitMapToMarkers();
    }
  }, [user]);

  useEffect(() => {
    const fetchDrivers = async () => {
        try {
            // Extract all driver IDs from deliveryPoints and orders
            const driverIds = [
                ...new Set([
                    ...deliveryPoints.map(dp => dp.driver_id),
                    ...orders.map(order => order.driver_id)
                ])
            ];

            if (driverIds.length > 0) {
      
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/drivers`, {
                    driver_ids: driverIds
                });

                setDrivers(response.data.drivers);
                console.log('Drivers:', response.data.drivers);
            }
        } catch (error) {
            console.error('Error fetching drivers:', error);
        }
    };

    // Run the fetchDrivers function immediately and then every 2 seconds
    fetchDrivers();
    const intervalId = setInterval(fetchDrivers, 3000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
}, [deliveryPoints]);
  
  useEffect(() => {
    if (mapRef.current) {
      initializeDriverMarkers();
    }
  }, [drivers]);

  useEffect(() => {
    if (mapRef.current) {
      initializeDeliveryPointMarkers();
      // fitMapToMarkers();
    }
  }, [deliveryPoints]);
  
  useEffect(() => {
    if (mapRef.current && orders.length > 0 && user.addresses.length > 0) {
      initializeUserMarkers();
    }
  }, [orders, user]);


  const initializeUserMarkers = () => {
    // Clear existing markers
    userMarkers.forEach(marker => marker.setMap(null));
  
    const newMarkers = orders.map(order => {
      const address = user.addresses.find(addr => addr.id === order.address_id);
      if (address && address.latitude && address.longitude) {
        const marker = new window.google.maps.Marker({
          position: { lat: address.latitude, lng: address.longitude },
          map: mapRef.current,
          icon: {
            url: `${process.env.PUBLIC_URL}/icons/store.svg`, // Path to your SVG icon
            scaledSize: new window.google.maps.Size(40, 40), // Adjust size as needed
          },
        });
        return marker;
      }
      return null;
    }).filter(marker => marker);
  
    setUserMarkers(newMarkers);
  };



  const initializeDeliveryPointMarkers = () => {
    // Remove existing markers from the map
    Object.values(deliveryPointMarkers).forEach(marker => marker.setMap(null));
    const newMarkers = {};
  
    deliveryPoints.forEach(point => {
      let { latitude, longitude } = point;
  
      // Define the default icon path based on the status
      let iconUrl;
      switch (point.status) {
        case 'pending':
          iconUrl = `${process.env.PUBLIC_URL}/icons/pin-orange.svg`;
          break;
        case 'entregado':
          iconUrl = `${process.env.PUBLIC_URL}/icons/pin-green.svg`;
          break;
        case 'cancelado':
          iconUrl = `${process.env.PUBLIC_URL}/icons/pin-red.svg`;
          break;
        case 'encamino':
          iconUrl = `${process.env.PUBLIC_URL}/icons/pin-blue.svg`;
          break;
        default:
          iconUrl = `${process.env.PUBLIC_URL}/icons/pin-grey.svg`;
      }
  
      // Check if latitude and longitude are missing
      if (!latitude || !longitude) {
        const districtData = districts[point.district];
        if (districtData) {
          latitude = districtData.latitude + (Math.random() - 0.5) * 0.01;
          longitude = districtData.longitude + (Math.random() - 0.5) * 0.01;
        } else {
          console.warn(`No district data found for point: ${point.district}`);
          return; // Skip this point if no coordinates or district data is available
        }
      }
  
      // Create a Google Maps marker if we have valid coordinates
      const marker = new window.google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map: mapRef.current,
        icon: {
          url: iconUrl, // Path to the house icon based on the status
          scaledSize: new window.google.maps.Size(40, 40), // Adjust size as needed
        },
        animation: window.google.maps.Animation.BOUNCE,
      });
  
      // Add click listener for marker
      marker.addListener('click', () => {
        setSelectedPoint(point);
        mapRef.current.panTo({ lat: latitude, lng: longitude });
        mapRef.current.setZoom(16);
      });
  
      // Store the marker in the newMarkers object
      newMarkers[point.id] = marker;
    });
  
    // Update state with the new markers
    setDeliveryPointMarkers(newMarkers);
  };

  const initializeDriverMarkers = () => {
    // Clear existing markers from the map
    Object.values(driverMarkers).forEach(marker => marker.setMap(null));
    const newMarkers = {};
  
    drivers.forEach(driver => {
      if (driver.longitude && driver.latitude) {
        // Create a custom icon for the marker (using your logo image)
        const logoIcon = {
          url: `${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`, // Path to your logo image
          scaledSize: new window.google.maps.Size(30, 30), // Size of the icon
        };
  
        // Create the marker for the driver
        const marker = new window.google.maps.Marker({
          position: { lat: driver.latitude, lng: driver.longitude },
          map: mapRef.current,
          icon: logoIcon,
        });
  
        // Store the marker in the newMarkers object using the driver ID
        newMarkers[driver.id] = marker;
      }
    });
  
    // Update state with the new markers
    setDriverMarkers(newMarkers);
  };

const handleClosePopup = () => {
    setSelectedPoint(null);
    // fitMapToMarkers(); // Reset map zoom to include all markers
  };

 
const handleLogoClick = () => {
  setActiveComponent('Options')
};

  return (
    <div>
      <div
        ref={mapContainerRef}
        style={{ width: "100%", height: "70vh" }}
      ></div>

      <div
        style={{
          position: "absolute",
          top: "15px",
          left: "15px",
          zIndex: 2000, // Ensure it stays on top
        }}
        onClick={handleLogoClick}
      >
        <img
          src={`${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`}
          alt="Logo"
          style={{ height: "58px", width: "58px" }}
        />
      </div>
      <div
        style={{
          width: "75%",
          position: "absolute",
          top: "80px",
          left: "10px",
          zIndex: 2000, // Ensure it stays on top
        }}
      >
        {selectedPoint && (
          <MarkerText point={selectedPoint} onClose={handleClosePopup} />
        )}
      </div>
      <div
        style={{
          position: "absolute",
          top: "160px",
          borderRadius: "50%",
          left: "35%",
          backgroundColor: "white",
          marginBottom: "50px",
        }}
      >
      </div>
      {orders.length > 0 &&(
        <IconButton
        style={{
          position: "absolute",
          bottom: "54%",
          right: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.0)",
        }}
        onClick={() => setActiveComponent('Message')}
      >
        <WhatsAppIcon />
      </IconButton>
      )}
      {orders.length > 0 &&(
        <IconButton
        style={{
          position: "absolute",
          bottom: "62%",
          right: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.0)",
        }}
        onClick={handleRecojoClick}
      >
        <LocationOnIcon />
      </IconButton>
      )}
      {orders.length > 0 && (
        <IconButton
          style={{
            position: "absolute",
            bottom: "44%",
            right: 30,
            zIndex: 1000,
            backgroundColor: "white",
            border: "2px solid black",
            borderRadius: "80%",
            color: "black",
            marginBottom: "10px",
            transform: "scale(1.7)",
          }}
          onClick={handleEntregaClick}
        >
          <AddIcon />
        </IconButton>
      )}
      <div
        style={{
          height: "30vh",
          padding: "20px",
          backgroundColor: "#f7f7f7",
          overflowY: "auto",
        }}
      >
        {user && (
          <MenuUser
            user={user}
            deliveryPoints={deliveryPoints}
            setActiveComponent={setActiveComponent}
            orders={orders}
            handleRecojoClick={handleRecojoClick}
          />
        )}
      </div>
    </div>
  );
};

export default Maps;