import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Alert, AlertTitle } from '@mui/material';
import { IconButton } from '@mui/material';

const FinalClient = ({ deliveryPoint }) => {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const [driverMarker, setDriverMarker] = useState(null);
    const [driverLocation, setDriverLocation] = useState(null);
    const boundsRef = useRef(null);

    useEffect(() => {
        // Load Google Maps script
        const loadGoogleMapsScript = () => {
            if (!window.google) {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
                script.async = true;
                script.defer = true;
                script.onload = () => initMap();
                document.head.appendChild(script);
            } else {
                initMap();
            }
        };

        const initMap = () => {
            if (mapContainerRef.current) {
                const mapInstance = new window.google.maps.Map(mapContainerRef.current, {
                    center: { lat: deliveryPoint.latitude, lng: deliveryPoint.longitude },
                    zoom: 15,
                    disableDefaultUI: true, // Disable default UI controls
                });

                mapRef.current = mapInstance;
                boundsRef.current = new window.google.maps.LatLngBounds();

                // Add delivery point marker
                const deliveryLatLng = new window.google.maps.LatLng(deliveryPoint.latitude, deliveryPoint.longitude);
                const deliveryMarker = new window.google.maps.Marker({
                    position: deliveryLatLng,
                    map: mapInstance,
                    icon: {
                        url: 'https://maps.google.com/mapfiles/kml/shapes/homegardenbusiness.png',
                        scaledSize: new window.google.maps.Size(40, 40),
                    },
                });

                boundsRef.current.extend(deliveryLatLng);
                mapInstance.fitBounds(boundsRef.current);
            }
        };

        loadGoogleMapsScript();
    }, [deliveryPoint]);

    useEffect(() => {
        const fetchDriverLocation = async () => {
            if (deliveryPoint && deliveryPoint.driver_id) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/location/driver`, {
                        params: {
                            driver_id: deliveryPoint.driver_id,
                        },
                    });
                    setDriverLocation(response.data);
                } catch (error) {
                    console.error('Error fetching driver location:', error);
                }
            }
        };

        const intervalId = setInterval(fetchDriverLocation, 4000);

        return () => clearInterval(intervalId);
    }, [deliveryPoint]);

    useEffect(() => {
        if (driverLocation && mapRef.current) {
            // Remove previous driver marker if it exists
            if (driverMarker) {
                driverMarker.setMap(null);
            }

            // Create new driver marker
            const newDriverMarker = new window.google.maps.Marker({
                position: { lat: driverLocation.latitude, lng: driverLocation.longitude },
                map: mapRef.current,
                icon: {
                    url: 'https://maps.google.com/mapfiles/kml/shapes/motorcycling.png', // Changed to motorcycle icon
                    scaledSize: new window.google.maps.Size(40, 40),
                },
            });
            setDriverMarker(newDriverMarker);

            // Extend bounds to include driver location and keep both markers in view
            const driverLatLng = new window.google.maps.LatLng(driverLocation.latitude, driverLocation.longitude);
            boundsRef.current.extend(driverLatLng);
            mapRef.current.fitBounds(boundsRef.current);
        }
    }, [driverLocation]);

    return (
        <div style={{ height: "100vh" }}>
            <div ref={mapContainerRef} style={{ height: "60vh" }}></div>
            {deliveryPoint.latitude && deliveryPoint.longitude && (
          <>
            <div
              style={{
                height: "40vh",
                padding: "20px",
                backgroundColor: "#f7f7f7",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  padding: "20px",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  color: "#000",
                }}
              >
                <h2>¡Hola {deliveryPoint.recipient_name}!</h2>
                <p>{deliveryPoint.user_name} tiene una entrega para ti. CODIGO: {deliveryPoint.id}</p>
                {deliveryPoint.charge > 0 && (
                  <p>
                    El monto a pagar es de{" "}
                    <strong>S/.{deliveryPoint.charge}</strong>.
                  </p>
                )}
                {driverLocation && (
                  <Alert severity="info" style={{ marginTop: "20px" }}>
                    <AlertTitle>Información del Repartidor</AlertTitle>
                    <p>
                      Tu delivery será entregado por <strong>{driverLocation.name}</strong>
                      {deliveryPoint.arrival_time && (() => {
                        // Create a new Date object from arrival_time
                        const arrivalTime = new Date(deliveryPoint.arrival_time);
                        
                        // Add two hours to adjust the time
                        arrivalTime.setHours(arrivalTime.getHours() - 5);
                        
                        // Calculate the start and end of the delivery window
                        const startWindow = new Date(arrivalTime);
                        startWindow.setMinutes(startWindow.getMinutes() - 30); // 30 minutes before arrival time

                        const endWindow = new Date(arrivalTime);
                        endWindow.setMinutes(endWindow.getMinutes() + 30); // 30 minutes after arrival time


                        // Function to format time as 'HH:MM'
                        const formatTime = (date) => date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                        
                        return (
                          <>
                            {" "}entre <strong>{formatTime(startWindow)}</strong> y <strong>{formatTime(endWindow)}</strong>.
                          </>
                        );
                      })()}
                      . Verifica el código de entrega al recibir tu pedido.
                    </p>
                  </Alert>
                )}
              </div>
            </div>
            
          </>
        )}
        </div>
    );
};

export default FinalClient;
