import React, { useState, useEffect } from "react";
import axios from "axios";
import { Alert, AlertTitle } from '@mui/material';
import dayjs from 'dayjs';
import OrderDriver from './OrderDriver';
import DeliveryDriver from './DeliveryDriver';

function MenuDriver({ user, userId, deliveryPoints, setMainDeliveryPoints, setMainOrder, setActiveComponent, orderPoints, onBackClick }) {
    const currentDate = dayjs().format('YYYY-MM-DD');
    const [deliveries, setDeliveries] = useState([]);
    const [orders, setOrders] = useState([]);
    const [selectedDeliveryPoint, setSelectedDeliveryPoint] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);

    useEffect(() => {
        const fetchOrderPoints = async () => {
            
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_orders/driver/${userId}`);
                setOrders(response.data);
                console.log('Fetched order points:', response.data);
            } catch (error) {
                console.error('Error fetching order points:', error);
            }
        };

        fetchOrderPoints();
    }, [userId]);


    useEffect(() => {
        const fetchDeliveryPoints = async () => {
            const token = localStorage.getItem('token');
            try { 
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_points/driver/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setDeliveries(response.data);
                console.log('Fetched delivery points:', response.data);
            } catch (error) {
                console.error('Error fetching delivery points:', error);
            }
        }; 
        fetchDeliveryPoints();
    }, [userId]);

    

    if (selectedOrder) {
        return <OrderDriver order={selectedDeliveryPoint} onBack={() => setSelectedOrder(null)} onBackClick={onBackClick} />;
    }

    if (selectedDeliveryPoint) {
        return <DeliveryDriver delivery={selectedDeliveryPoint} onBack={() => setSelectedDeliveryPoint(null)} onBackClick={onBackClick} />;
    }

    const statusOrder = {
        pending: 'Agendado',
        encamino: 'En camino',
        recogido: 'Recogido'
    };

    const statusDeliveryPoint = {
        pending: 'Pendiente',
        encamino: 'En ruta',
        entregado: 'Entregado'
    };

    const currentDayDeliveryPoints = deliveries.filter(point => 
        dayjs(point.delivery_date).format('YYYY-MM-DD') === currentDate
    );

    const nextOrder = orders
        .filter(order => !['recogido', 'cancelado', 'pending'].includes(order.status))
        .sort((a, b) => a.sequence - b.sequence)[0];

    const nextDeliveryPoint = currentDayDeliveryPoints
        .filter(point => !['entregado', 'cancelado', 'pospuesto', 'pending'].includes(point.status))
        .sort((a, b) => a.sequence - b.sequence)[0];

    const handleDeliveryClick = (delivery) => {
        setMainDeliveryPoints(delivery);
        setActiveComponent('DeliveryDriver');
    };

    const handleOrderClick = (order) => {
        setMainOrder(order);
        setActiveComponent('OrderDriver');
    };

    return (
        <div style={{
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            color: "#000",
        }}>
            <h2>¡Hola {user?.username || 'Conductor'}!</h2>
            {nextOrder ? (
                <Alert severity="info" style={{ marginTop: "20px", cursor: "pointer" }} onClick={() => handleOrderClick(nextOrder)} key={nextOrder.id}>
                    <AlertTitle>Punto {nextOrder.sequence} Recojo ID: {nextOrder.id} {nextOrder.customer_name} {statusOrder[nextOrder.status]}</AlertTitle>
                    <p>{nextOrder.driver_notes}</p>
                </Alert>
            ) : nextDeliveryPoint ? (
                <Alert severity="info" style={{ marginTop: "20px", cursor: "pointer" }} onClick={() => handleDeliveryClick(nextDeliveryPoint)} key={nextDeliveryPoint.id}>
                    <AlertTitle>Punto {nextDeliveryPoint.sequence} ID: {nextDeliveryPoint.id} {nextDeliveryPoint.user_name}</AlertTitle>
                    <p>{nextDeliveryPoint.notes}</p>
                </Alert>
            ) : (
                <Alert severity="info" style={{ marginTop: "20px" }}>
                    <AlertTitle>Esperando Ruta.</AlertTitle>
                </Alert>
            )}
        </div>
    );
}

export default MenuDriver;