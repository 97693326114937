import React, { useEffect, useState } from 'react';
import axios from 'axios';

function ConnectionHandler() {
    const [isOffline, setIsOffline] = useState(false);

    const checkConnection = async () => {
        try {
            await axios.get(`${process.env.REACT_APP_API_URL}/api/health_check`);
            setIsOffline(false);
        } catch (error) {
            setIsOffline(true);
        }
    };

    const handleRefresh = () => {
        window.location.reload();
    };

    useEffect(() => {
        const handleOnline = () => {
            setIsOffline(false);
            alert('You are back online.');
        };

        const handleOffline = () => {
            setIsOffline(true);
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                checkConnection();
            }
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        checkConnection();

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    return (
        isOffline && (
            <div style={{
                position: 'fixed',
                bottom: '20px',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: '#ff4d4f',
                color: 'white',
                padding: '10px 20px',
                borderRadius: '5px',
                zIndex: 1000,
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}>
                <p style={{ margin: 0 }}>Sin Coneccion, por favor vuelve a cargar la pagina.</p>
                <button
                    onClick={handleRefresh}
                    style={{
                        marginTop: '5px',
                        backgroundColor: '#ffffff',
                        color: '#ff4d4f',
                        border: 'none',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                >
                    Recargar pagina
                </button>
            </div>
        )
    );
}

export default ConnectionHandler;
