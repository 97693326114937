import React, { useState, useEffect } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import axios from 'axios';
import Login from "./Login";
import Register from "./Register";
import MainUser from "./user/MainUser";
import MainDriver from "./driver/MainDriver";
import MainAdmin from "./admin/MainAdmin";
import MainFinalClient from "./finalClient/MainFinalClient";
import ConnectionHandler from './ConnectionHandler';
import useTokenRefresh from './useTokenRefresh';



function App() {
  const [isUserRegistered, setIsUserRegistered] = useState(true);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState('');
  const [addresses, setAddresses] = useState([]);


  const handleLoginSuccess = (userId, username, email, phoneNumber, role, addresses) => {
    setIsUserLoggedIn(true);
    setUserId(userId);
    setUsername(username);
    setEmail(email);
    setPhoneNumber(phoneNumber);
    setRole(role);
    setAddresses(addresses || []);
  };

  const handleUpdateUser = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/${userId}`);
    setUsername(response.data.username);
    setEmail(response.data.email);
    setPhoneNumber(response.data.phone_number);
    setRole(response.data.role);
  };

  const handleLogout = () => {
    setIsUserLoggedIn(false);
    setIsUserRegistered(true);
    localStorage.clear();
  };

  const handleRegistrationSuccess = () => {
    setIsUserRegistered(true);
  };

  useEffect(() => {
    const currentUrl = window.location.pathname;
    const isDeliveryRoute = currentUrl.includes("deliveryId");

    if (!isDeliveryRoute) {
      const token = localStorage.getItem('token');
      const storedUserId = localStorage.getItem('user_id');
      const storedUsername = localStorage.getItem('username');
      const storedEmail = localStorage.getItem('email');
      const storedPhoneNumber = localStorage.getItem('phone_number');
      const storedRole = localStorage.getItem('role');
      let storedAddresses = [];

      // Safely parse storedAddresses from localStorage
      try {
        storedAddresses = JSON.parse(localStorage.getItem('addresses')) || [];
      } catch (error) {
        console.error("Failed to parse addresses from localStorage:", error);
        storedAddresses = [];
      }
      

      if (token && storedUserId && storedUsername && storedEmail && storedPhoneNumber && storedRole) {
        handleLoginSuccess(
          storedUserId,
          storedUsername,
          storedEmail,
          storedPhoneNumber,
          storedRole,
          storedAddresses
        );
      }
    }
  }, []);
  useTokenRefresh(isUserLoggedIn);

  return (
    <>
      <ConnectionHandler /> {/* Add this here to always monitor the connection status */}
      {isUserLoggedIn ? (
        <div className="container-fluid p-0">
          {role === 'customer' && (
            <MainUser onLogout={handleLogout} onUpdateUser={handleUpdateUser} user_id={userId} username={username} email={email} phone_number={phoneNumber} addresses={addresses} />
          )}
          {role === 'driver' && (
            <MainDriver onLogout={handleLogout} onUpdateUser={handleUpdateUser} user_id={userId} username={username} email={email} phone_number={phoneNumber} />
          )}
          {role === 'admin' && (
            <MainAdmin onLogout={handleLogout} onUpdateUser={handleUpdateUser} user_id={userId} username={username} email={email} phone_number={phoneNumber} />
          )}
        </div>
      ) : (
        <Router>
          <Routes>
            <Route path="/" element={
              isUserRegistered ? (
                <Login onLoginSuccess={handleLoginSuccess} setIsUserRegistered={setIsUserRegistered} />
              ) : (
                <Register onRegistrationSuccess={handleRegistrationSuccess} setIsUserRegistered={setIsUserRegistered}/>
              )
            } />
            <Route path="/:deliveryId" element={<MainFinalClient />} />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
