import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ButtonBase, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { IconButton } from "@mui/material";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeliveryDriver from './DeliveryDriver';

function DeliveryList({ onBackClick, userId }) {
    const [selectedDeliveryPoint, setSelectedDeliveryPoint] = useState(null);
    const [deliveries, setDeliveries] = useState([]);
    const [driver, setDriver] = useState(null);

    useEffect(() => {
        if (userId) {
            fetchDeliveryPoints();
        }
    }, [userId]);

    useEffect(() => {
        const fetchDriverData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/location/driver`, {
                    params: { driver_id: userId },
                });
                setDriver(response.data);
                console.log('Fetched driver:', response.data);
            } catch (error) {
                console.error('Error fetching driver:', error);
            }
        };
    
        if (userId) {
            fetchDriverData();
        }
    }, [userId]);

    const fetchDeliveryPoints = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_points/driver/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setDeliveries(response.data);
            console.log('Fetched delivery points:', response.data);
        } catch (error) {
            console.error('Error fetching delivery points:', error);
        }
    };

    const handleDeliveriesRoutes = async () => {
        if (deliveries.length === 0) return;

        // Sort delivery points by sequence and filter by pending status
        const sortedDeliveries = deliveries
            .filter(delivery => delivery.status === 'pending')
            .sort((a, b) => a.sequence - b.sequence);

        if (sortedDeliveries.length === 0) {
            console.log('No pending deliveries to process.');
            return;
        }

        console.log('Processing pending deliveries:', sortedDeliveries);

        // Set hardcoded latitude and longitude for Lima Centro
        const startPoint = { 
            lat: driver.latitude, 
            lng: driver.longitude,
        }; // Driver coordinates

        // Define the end point using the last item in the sorted delivery points, excluding it from waypoints
        const endPoint = {
            lat: sortedDeliveries[sortedDeliveries.length - 1].latitude,
            lng: sortedDeliveries[sortedDeliveries.length - 1].longitude,
        };

        // Create waypoints using direct latitude and longitude values from each delivery point except the last one
        const stops = sortedDeliveries.slice(0, -1).map(delivery => ({
            lat: delivery.latitude,
            lng: delivery.longitude,
        }));

        const deliveryIds = sortedDeliveries.map(delivery => delivery.id);

        try {
            // Create the route using the hardcoded Lima Centro as the start point
            const route = await createGoogleRoute(startPoint, endPoint, stops, deliveryIds);

            console.log('Arrival times updated successfully');

            // Fetch updated deliveries to reflect "encamino" status changes in the UI
            await fetchDeliveryPoints();

            // Show success message
            alert('Empezaste tu ruta, suerte!');

        } catch (error) {
            console.error('Error updating arrival times:', error);
            alert('Algo falló, por favor intenta de nuevo.');
        }
    };

    const handleEntregasRoutesOptimized = async () => {
        if (deliveries.length === 0) return;
    
        // Define the fixed start point (Lima Centro) and the driver's location as the end point
        const startPoint = {
            lat: driver.latitude,
            lng: driver.longitude,
        };
        const endPoint = {
            lat: driver.final_latitude,
            lng: driver.final_longitude,
        };
    
        // Filter and extract waypoints and delivery IDs from the entregas array
        const waypoints = deliveries
            .filter(point => point.status === 'pending' && point.latitude && point.longitude)
            .map(point => ({
                lat: point.latitude,
                lng: point.longitude,
            }));
        
        const deliveryIds = deliveries
            .filter(point => point.status === 'pending' && point.latitude && point.longitude)
            .map(point => point.id);
    
        console.log('Start point:', startPoint);
        console.log('End point:', endPoint);
        console.log('Waypoints:', waypoints);
        console.log('Delivery IDs:', deliveryIds);
    
        // Call the backend to create the optimized route using Google Maps API
        try {
            const token = localStorage.getItem("token");
    
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/create_route_deliveries_optimized`,
                {
                    startPoint: `${startPoint.lat},${startPoint.lng}`,
                    endPoint: `${endPoint.lat},${endPoint.lng}`,
                    waypoints: waypoints.map(wp => `${wp.lat},${wp.lng}`).join('|'),
                    deliveryIds: deliveryIds,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            if (response.data.message === 'Optimized route created successfully and arrival times updated') {
                console.log('Full response:', response.data);
                alert('Tu ruta ha sido optimizada correctamente!');
                await fetchDeliveryPoints();
                
            } else {
                console.error('Backend response error:', response.data);
                alert('Fallo al optimizar ruta, tienes el GPS activado?.');
            }
        } catch (error) {
            console.error('Error creating optimized route:', error);
            alert('Failed to create optimized route. Please try again.');
        }
    };

    const createGoogleRoute = async (startPoint, endPoint, waypoints, deliveryIds) => {
        const token = localStorage.getItem('token');

        // Convert waypoints to the correct format for Google API
        const formattedWaypoints = waypoints.map(wp => `${wp.lat},${wp.lng}`).join('|');

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/create_route_deliveries`,
                {
                    startPoint: `${startPoint.lat},${startPoint.lng}`,
                    endPoint: `${endPoint.lat},${endPoint.lng}`,
                    waypoints: formattedWaypoints,
                    deliveryIds: deliveryIds,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.data.message !== 'Arrival times updated successfully') {
                console.error('Google Maps API response error:', response.data);
                throw new Error('Failed to create route with Google Maps API');
            }

            console.log('Full response:', response.data); // Add this line to debug response data
            return response.data.routes ? response.data.routes[0] : null; // Ensure routes[0] is what you need
        } catch (error) {
            console.error('Error creating route:', error);
            throw error;
        }
    };

    if (selectedDeliveryPoint) {
        return <DeliveryDriver delivery={selectedDeliveryPoint} onBack={() => setSelectedDeliveryPoint(null)} onBackClick={onBackClick} />;
    }

    return (
        <div style={{ maxHeight: '700px', overflowY: 'auto' }}>
            <ButtonBase onClick={() => { onBackClick() }} style={{ padding: '20px', color: 'black' }}>
                <ArrowBackIcon />
            </ButtonBase>
            <IconButton
                style={{
                    position: "absolute",
                    top: "20px",
                    right: 30,
                    zIndex: 1000,
                    backgroundColor: "white",
                    border: "2px solid black",
                    borderRadius: "50%",
                    color: "black",
                    marginBottom: "10px",
                    transform: "scale(0.9)",
                }}
                onClick={handleDeliveriesRoutes}
            >
                <PlayCircleIcon />
            </IconButton>
            <IconButton
                style={{
                    position: "absolute",
                    top: "20px",
                    right: 85,
                    zIndex: 1000,
                    backgroundColor: "white",
                    border: "2px solid black",
                    borderRadius: "50%",
                    color: "black",
                    marginBottom: "10px",
                    transform: "scale(0.9)",
                }}
                onClick={handleEntregasRoutesOptimized}
            >
                <InsightsIcon />
            </IconButton>
            <h1 style={{ color: 'black', fontSize: '2em', margin: '20px' }}>Entregas</h1>
            <List>
                {deliveries.sort((a, b) => a.sequence - b.sequence).map((delivery, index) => (
                    <ButtonBase
                        onClick={() => !delivery.is_submitted && setSelectedDeliveryPoint(delivery)}
                        key={index}
                        disabled={delivery.is_submitted} // Disable if order is already submitted
                    >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <SupportAgentIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<span style={{ color: 'black' }}>{delivery.id} Punto {delivery.sequence}</span>}
                                secondary={`${delivery.recipient_name.substring(0, 15)}, Estado: ${delivery.status}`}
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </ButtonBase>
                ))}
            </List>
        </div>
    );
}

export default DeliveryList;
