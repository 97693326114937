import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Button, IconButton, List, ListItem, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import mapboxgl from 'mapbox-gl';
import axios from 'axios';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const FindClientMap = ({ onClose, order }) => {
    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [mapInstance, setMapInstance] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [addressId, setAddressId] = useState(null);
    const address_id = order?.address_id;
    console.log('Address ID:', addressId);

    useEffect(() => {
        if (order) {
            console.log('User address:', order.address_id);
            setAddressId(order.address_id);  // Default to the first address if available
            
        }
    }, [order]);


    useEffect(() => {
        if (mapRef.current && window.google) {
            // Initialize the map
            const map = new window.google.maps.Map(mapRef.current, {
                center: { lat: -12.060235303763926, lng: -77.03711992646711 },
                zoom: 12,
                disableDefaultUI: true,
            });

            // Add a click listener to place a marker
            map.addListener('click', (e) => {
                const location = {
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                };
                setSelectedLocation(location);
                addMarker(location, map);
            });

            setMapInstance(map);
        }
    }, []);

    const addMarker = (location, map) => {
        // Remove the previous marker
        if (markerRef.current) {
            markerRef.current.setMap(null);
        }

        // Place the new marker
        markerRef.current = new window.google.maps.Marker({
            position: location,
            map: map,
            icon: {
                url: `${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`,
                scaledSize: new window.google.maps.Size(30, 30),
            },
        });
    };

    const fetchAutocompleteSuggestions = async (input) => {
        if (input.trim().length === 0) {
            setSuggestions([]);
            return;
        }

        const autocompleteService = new window.google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions(
            {
                input,
                componentRestrictions: { country: 'pe' },
                location: new window.google.maps.LatLng(-12.0464, -77.0428), // Center around Lima
                radius: 50000, // Limit to approximately 50 km around Lima
            },
            (predictions, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
                    setSuggestions(predictions);
                } else {
                    setSuggestions([]);
                }
            }
        );
    };

    const handleInputChange = (event) => {
        const input = event.target.value;
        setSearchQuery(input);
        fetchAutocompleteSuggestions(input);
    };

    const handleSuggestionClick = (suggestion) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ placeId: suggestion.place_id }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
                const location = {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                };
                setSelectedLocation(location);
                addMarker(location, mapInstance);
                mapInstance.panTo(location);
                mapInstance.setZoom(15);
            }
        });
        setSearchQuery(suggestion.description);
        setSuggestions([]);
    };

    const handleSave = async () => {
        if (selectedLocation) {
            console.log("Sending selected location:", selectedLocation);
            try {
                const token = localStorage.getItem('token');
                await axios.post(`${process.env.REACT_APP_API_URL}/api/update/order_location`, {
                    address_id: addressId,
                    latitude: selectedLocation.lat,
                    longitude: selectedLocation.lng
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                console.log('Location saved successfully:', selectedLocation);
                onClose();
            } catch (error) {
                console.error('Error saving location:', error);
            }
        } else {
            alert('Please select a location first.');
        }
    };

    return (
        <Box sx={{ position: 'absolute', top: "80px", left: "620px", width: '100%', maxWidth: 500, padding: 2, border: '1px solid #ddd', borderRadius: 2, backgroundColor: 'white', zIndex: '3000' }}>
            <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
                <CloseIcon />
            </IconButton>
            <TextField
                label="Busca una Direccion"
                value={searchQuery}
                onChange={handleInputChange}
                fullWidth
                sx={{
                    marginBottom: 2,
                    position: 'relative',
                    zIndex: 10000, // Set a higher z-index to make sure it's always above the map
                }}
                InputProps={{
                    style: {
                        border: 'none',
                    },
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            {/* Render the autocomplete suggestions below the search bar */}
            {suggestions.length > 0 && (
                <Box sx={{
                    position: 'relative',
                    zIndex: 10000,
                    maxHeight: '150px',
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    backgroundColor: 'white',
                    marginBottom: 2,
                    borderRadius: 1,
                }}>
                    <List>
                        {suggestions.map((suggestion) => (
                            <ListItem button key={suggestion.place_id} onClick={() => handleSuggestionClick(suggestion)}>
                                <ListItemText primary={suggestion.description} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}
            <Box
                ref={mapRef}
                sx={{ height: '300px', marginTop: 2, position: 'relative' }}
            />
            <Button onClick={handleSave} variant="contained" fullWidth sx={{ marginTop: 2 }}>
                Guardar Ubicacion
            </Button>
        </Box>
    );
};

export default FindClientMap;
