import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import { Box, Typography, Button, Card } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AddImage = ({ onClose, deliveryId }) => {
    const [images, setImages] = useState([]);
    const [previewImages, setPreviewImages] = useState([]);  // For previewing images
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);


    const onDrop = (acceptedFiles) => {
        setImages(acceptedFiles);
        
        // Create preview URLs for the uploaded images
        const previewUrls = acceptedFiles.map(file => URL.createObjectURL(file));
        setPreviewImages(previewUrls);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleUpload = async () => {
        if (!images.length) {
            setMessage('Porfavor selecciona una o más imágenes primero.');
            return;
        }
        
        setLoading(true); // Set loading state to true
        setMessage('Cargando...'); // Display loading message

        const formData = new FormData();
        images.forEach((image) => {
            formData.append('images', image);
        });

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload_image/${deliveryId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            setMessage(response.data.message);
            onClose();  // Close the modal after successful upload
        } catch (error) {
            setMessage(error.response?.data?.error || 'Upload failed.');
        }
    };

    return (
        <Card sx={{ maxWidth: 300, position: 'absolute', top: "10px", left: "220px", maxHeight: "350px", overflowY: 'auto' }}>
            <Box sx={{ padding: 3 }}>
                {/* Back Arrow */}
                <IconButton onClick={onClose} style={{ marginBottom: '5px', color: 'black' }}>
                    <ArrowBackIcon />
                </IconButton>

                {/* Dropzone */}
                <div
                    {...getRootProps()}
                    style={{
                        backgroundColor: 'white',
                        border: '2px dashed black',
                        borderRadius: '8px',
                        textAlign: 'center',
                        marginBottom: '5px',
                        cursor: 'pointer',
                    }}
                >
                    <input {...getInputProps()} />
                    <DriveFolderUploadIcon style={{
                        fontSize: '50px',
                        color: 'black',
                    }} />
                </div>

                {/* Preview Carousel */}
                {previewImages.length > 0 && (
                    <Carousel>
                        {previewImages.map((src, index) => (
                            <Box key={index} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                <img src={src} alt={`Preview ${index}`} style={{ maxHeight: '100%', maxWidth: '100%' }} />
                            </Box>
                        ))}
                    </Carousel>
                )}

                {/* Upload Button */}
                <Button
                    variant="contained"
                    onClick={handleUpload}
                    startIcon={<CheckCircleIcon />}
                    sx={{ backgroundColor: 'green', color: 'white', marginBottom: 2 }}
                >
                    {loading ? 'Cargando...' : 'Ok'}
                </Button>

                {/* Message */}
                {message && (
                    <Box sx={{ marginTop: 2, color: message.includes('failed') ? 'red' : 'green' }}>
                        <Typography variant="body1">{message}</Typography>
                    </Box>
                )}
            </Box>
        </Card>
    );
};

export default AddImage;
