import React, { useState, useEffect, useRef } from 'react';
import { Box, Card, CardContent, CardMedia, Typography, IconButton } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import FindClientMap from './FindClientMap';    
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import EditionOrderCard from './EditionOrderCard';
import axios from 'axios';

const SearchOrderResultCard = ({ onSearch, order, onClose }) => {
    const { id, customer_id, driver_id, order_notes, recojo_date, customer_name, address, latitude, longitude, telefono, driver_notes, status, image_urls } = order;
    const [showMapUserCard, setShowMapUserCard] = useState(false);
    const [mapInstance, setMapInstance] = useState(null);
    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const [showEditionCard, setShowEditionCard] = useState(false);

    useEffect(() => {
        if (mapRef.current && window.google) {
            // Initialize Google Map
            const map = new window.google.maps.Map(mapRef.current, {
                center: {
                    lat: latitude || -12.0464,
                    lng: longitude || -77.0428,
                },
                zoom: 14,
                disableDefaultUI: true,
            });

            // Add a marker if latitude and longitude are available
            if (latitude && longitude) {
                addMarker({ lat: latitude, lng: longitude }, map);
            }

            setMapInstance(map);
        }
    }, [latitude, longitude]);

    const addMarker = (location, map) => {
        // Remove the previous marker
        if (markerRef.current) {
            markerRef.current.setMap(null);
        }

        // Place a new marker
        markerRef.current = new window.google.maps.Marker({
            position: location,
            map: map,
            icon: {
                url: `${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`,
                scaledSize: new window.google.maps.Size(30, 30),
            },
        });
    };

    const handleEditClick = () => {
        setShowEditionCard(true);
    };

    const handleCloseEditionCard = () => {
        setShowEditionCard(false);
    };

    const handleDeleteClick = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete this order?");
        if (!confirmDelete) return;

        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete_order/${id}`);
            if (response.status === 200) {
                alert('Order deleted successfully');
                onClose();  // Close the card after deletion
            } else {
                alert('Failed to delete order');
            }
        } catch (error) {
            console.error('Error deleting order:', error);
            alert('Error deleting order');
        }
    };

    const handleUserClick = async () => {
        let response;

        response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search/user`, {
            params: { id: customer_id },
        });
        onSearch(response.data, 'user');
    };

    const handleFindUserMapClick = () => {
        setShowMapUserCard(true);
    };

    const handleCloseFindUserMapCard = () => {
        setShowMapUserCard(false);
    };

    const handleDriverClick = async () => {
        let response;

        response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search/user`, {
            params: { id: driver_id },
        });
        onSearch(response.data, 'driver');
    };

    const handleChangeDriverClick = (orderId) => {
        const newDriverId = prompt("Por favor, ingresa el nuevo ID del conductor:", "");

        if (newDriverId === null || newDriverId.trim() === "") {
            alert("Operación cancelada o ID no válido.");
            return;
        }

        axios.post(`${process.env.REACT_APP_API_URL}/api/update_order/driver_id`, {
            id: orderId,
            driver_id: newDriverId.trim(),
        })
            .then(() => {
                alert("El conductor ha sido actualizado exitosamente.");
            })
            .catch(error => {
                console.error('Error updating driver:', error);
                alert('No se pudo actualizar el conductor.');
            });
    };

    const handleOrderSequenceChange = async () => {
        const newSequence = prompt("Ingrese el nuevo número de secuencia:", order.sequence || 0);
        if (newSequence && !isNaN(newSequence)) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/change/order_sequence`, {
                    id: order.id,
                    sequence: newSequence,
                });

                if (response.status === 200) {
                    alert('Secuencia actualizada exitosamente');
                } else {
                    alert('Error al actualizar la secuencia');
                }
            } catch (error) {
                console.error('Error al actualizar la secuencia:', error);
                alert('Ocurrió un error al actualizar la secuencia');
            }
        } else {
            alert('Por favor, ingrese un número válido.');
        }
    };

    const handleWhatsAppClick = () => {
        window.open(`https://wa.me/51${telefono}`, '_blank');
    };

    const handleMessageClick = async (id, type) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/update_treinta_minutos`, {
                id: id,
                type: type,  // either 'order' or 'delivery'
            });

            if (response.status === 200) {
                window.alert(`El chamo llega en 30 minutos`);
            } else {
                console.error(`Failed to update ${type} tres_minutos`);
            }
        } catch (error) {
            console.error(`Error updating ${type} tres_minutos:`, error);
        }
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Card sx={{ maxWidth: 600, position: 'relative', top: "80px", left: "10px" }}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {customer_name} {id} {status} {new Date(recojo_date).toLocaleDateString()}
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body2" color="text.secondary">
                            {address}, {order_notes}, {driver_id}
                        </Typography>
                        <Box>
                            <IconButton size="small" onClick={() => handleMessageClick(order.id, 'order')}>
                                <AccessAlarmIcon />
                            </IconButton>
                            <IconButton size="small" onClick={() => handleChangeDriverClick(id)}>
                                <AccessibleForwardIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleOrderSequenceChange}>
                                <FormatListNumberedRtlIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleEditClick}>
                                <EditIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleDeleteClick}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleDriverClick}>
                                <SportsMotorsportsIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleUserClick}>
                                <PersonIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleFindUserMapClick}>
                                <InfoIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body2" color="text.secondary">
                            {driver_notes}
                        </Typography>
                    </Box>
                </CardContent>

                <Box sx={{ display: 'flex', flexDirection: 'row', height: '320px', marginTop: 2 }}>
                    <Box sx={{ width: '35%', height: '100%' }}>
                        <Carousel>
                            {image_urls && image_urls.length > 0 ? (
                                image_urls.map((url, index) => (
                                    <CardMedia
                                        key={index}
                                        component="img"
                                        alt={`Order ${id} image ${index + 1}`}
                                        height="320"
                                        image={url}
                                    />
                                ))
                            ) : (
                                <CardMedia
                                    component="img"
                                    alt={`Order ${id} image`}
                                    height="320"
                                    image={`${process.env.PUBLIC_URL}/llamaEnMoto.webp`}
                                />
                            )}
                        </Carousel>
                    </Box>
                    <Box sx={{ width: '65%', height: '100%' }}>
                        <Box
                            ref={mapRef}
                            sx={{ width: '100%', height: '100%' }}
                        />
                    </Box>
                </Box>

                <IconButton
                    size="medium"
                    onClick={onClose}
                    style={{ position: 'absolute', top: 7, right: 7, backgroundColor: 'white', zIndex: 1 }}
                >
                    <CloseIcon />
                </IconButton>
            </Card>

            {showEditionCard && (
                <EditionOrderCard order={order} onClose={handleCloseEditionCard} />
            )}
            {showMapUserCard && order && (
                <FindClientMap onClose={handleCloseFindUserMapCard} order={order} />
            )}
        </Box>
    );
};

export default SearchOrderResultCard;
