import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';  
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Carousel from 'react-material-ui-carousel';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import AddImageCard from './AddImageDriver';
import Box from '@mui/material/Box'; 

const MarkerTextDriver = ({ point, onClose }) => {
    const { id, customer_name, order_notes, charge, charged, transfer, recipient_name, address, image_urls, notes, user_name, notes_driver } = point;
    const [showAddImageCard, setShowAddImageCard] = useState(false);
    // Default image paths
    const defaultImages = [
        `${process.env.PUBLIC_URL}/llamaEnMoto.webp`,
        `${process.env.PUBLIC_URL}/llamaLima.webp`
    ];

    // Use default images if no image_urls are provided
    const images = image_urls && image_urls.length > 0 ? image_urls : defaultImages;

    const handleCloseAddImageCard = () => {
        setShowAddImageCard(false);
    };

    const handleAddImageClick = () => {
        setShowAddImageCard(true);
    };

    return (
        <Box>
        <Card sx={{ maxWidth: 220, position: 'relative' }}>
            <Carousel>
                {images.map((image, index) => (
                    <CardMedia
                        key={index}
                        component="img"
                        alt={`${recipient_name} image ${index + 1}`}
                        height="200"
                        sx={{ width: '70%', margin: '0 auto' }}
                        image={image}
                    />
                ))}
            </Carousel>
            <CardContent>

                {customer_name && (
                        <Typography variant="body2" color="text.secondary">
                        {customer_name.substring(0, 17)}
                </Typography>
                )}

                {recipient_name && 
                (<Typography gutterBottom variant="h5" component="div">
                    {recipient_name.substring(0, 17)} {id}
                </Typography>
                )}
                {address && (
                    <Typography variant="body2" color="text.secondary">
                    {address.substring(0, 17)}, {charge}, {user_name}
                </Typography>
                )}
                {id && (
                    <Typography variant="body2" color="text.secondary">
                    {id}
               </Typography>
                )}
                {order_notes && (
                    <Typography variant="body2" color="text.secondary">
                    {order_notes.substring(0, 17)}
               </Typography>
                )}

                
                
                {notes && ( 
                    <Typography variant="body2" color="text.secondary">
                     {notes.substring(0, 17)}
                </Typography>
                )}
                
                {notes_driver && (
                    <Typography variant="body2" color="text.secondary">
                        {notes_driver.substring(0, 17)}
                    </Typography>
                )}
                {notes_driver && (
                    <IconButton size="small" onClick={handleAddImageClick}>
                        <AddAPhotoIcon />
                    </IconButton>
                )}
                
                {charged && (
                    <Typography variant="body2" color="text.secondary">
                        {charged} // {transfer ? 'Transferido a fastmax' : ''}
                    </Typography>
                )}
            </CardContent>
            <IconButton
                size="medium"
                onClick={onClose}
                style={{ position: 'absolute', top: 7, right: 7, backgroundColor: 'white', zIndex: 1 }}
            >
                <CloseIcon />
            </IconButton>
            
        </Card>
        {showAddImageCard && (
                <AddImageCard deliveryId={id} onClose={handleCloseAddImageCard} />
            )}
        </Box>
    );
};


export default MarkerTextDriver;