import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ButtonBase, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { IconButton } from "@mui/material";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OrderDriver from './OrderDriver';

function OrderList({ onBackClick, userId }) {
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [orderPoints, setOrderPoints] = useState([]);
    const [driver, setDriver] = useState(null);

    useEffect(() => {
        if (userId) {
            fetchOrderPoints();
        }
    }, [userId]);

    useEffect(() => {
        const fetchDriverData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/location/driver`, {
                    params: { driver_id: userId },
                });
                setDriver(response.data);
                console.log('Fetched driver:', response.data);
            } catch (error) {
                console.error('Error fetching driver:', error);
            }
        };
    
        if (userId) {
            fetchDriverData();
        }
    }, [userId]);

    const fetchOrderPoints = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_orders/driver/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setOrderPoints(response.data);
            console.log('Fetched order points:', response.data);
        } catch (error) {
            console.error('Error fetching order points:', error);
        }
    };

    const handlePickupRoutes = async () => {
        if (orderPoints.length === 0) return;
    
        // Sort order points by sequence and filter by pending status
        const sortedOrderPoints = orderPoints
            .filter(order => order.status === 'pending')
            .sort((a, b) => a.sequence - b.sequence);
    
        if (sortedOrderPoints.length === 0) {
            console.log('No pending orders to process.');
            return;
        }
    
        console.log('Processing pending orders:', sortedOrderPoints);
    
        // Set hardcoded latitude and longitude for Lima Centro
        const startPoint = { 
            lat: driver.latitude, 
            lng: driver.longitude,
        }; // Hardcoded coordinates for Lima Centro
    
        // Define the end point using the last item in the sorted order points, excluding it from waypoints
        const endPoint = {
            lat: sortedOrderPoints[sortedOrderPoints.length - 1].latitude,
            lng: sortedOrderPoints[sortedOrderPoints.length - 1].longitude,
        };
    
        // Create waypoints using direct latitude and longitude values from each order point except the last one
        const stops = sortedOrderPoints.slice(0, -1).map(order => ({
            lat: order.latitude,
            lng: order.longitude,
        }));
    
        const orderIds = sortedOrderPoints.map(order => order.id);
    
        try {
            // Create the route using the hardcoded Lima Centro as the start point
            const route = await createGoogleRoute(startPoint, endPoint, stops, orderIds);
    
           
    
            console.log('Arrival times updated successfully');

            // Fetch updated orders to reflect "encamino" status changes in the UI
            await fetchOrderPoints();

            // Show success message
            alert('Empezaste tu ruta, suerte!');
    
        } catch (error) {
            console.error('Error updating arrival times:', error);
            alert('Algo falló, por favor intenta de nuevo.');
        }
    };

    const handlePickupRoutesOptimized = async () => {
        if (orderPoints.length === 0) return;
    
        // Define the start point (driver's location) and the fixed end point
        const startPoint = {
            lat: driver.latitude,
            lng: driver.longitude
        };
        const endPoint = {
            lat: -12.00093305507352,
            lng: -77.05374889286432
        };
    
        // Extract waypoints (pickup locations) from the recojos array
        const waypoints = orderPoints
            .filter(order => order.status === 'pending')
            .map(point => ({
                lat: point.latitude,
                lng: point.longitude
            }));
    
        // Collect order IDs for sending to the backend
        const orderIds = orderPoints
            .filter(order => order.status === 'pending')
            .map(point => point.id);
    
        console.log('Driver location:', startPoint);
        console.log('End point:', endPoint);
        console.log('Waypoints:', waypoints);
        console.log('Order IDs:', orderIds);
    
        // Call the backend to create the optimized route using Google Maps API
        try {
            const token = localStorage.getItem("token");
    
            // Call the backend route directly
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/create_route_optimized`,
                {
                    startPoint: `${startPoint.lat},${startPoint.lng}`,
                    endPoint: `${endPoint.lat},${endPoint.lng}`,
                    waypoints: waypoints.map(wp => `${wp.lat},${wp.lng}`).join('|'),
                    orderIds: orderIds
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            
    
            console.log('Full response:', response.data);
            alert('Tu ruta ha sido optimizada correctamente!');

            await fetchOrderPoints();

        } catch (error) {
            console.error('Error creating optimized route:', error);
            alert('Fallo al optimizar ruta, tienes el GPS activado?.');
        }
    };
    
    const createGoogleRoute = async (startPoint, endPoint, waypoints, orderIds) => {
        const token = localStorage.getItem('token');
    
        // Convert waypoints to the correct format for Google API
        const formattedWaypoints = waypoints.map(wp => `${wp.lat},${wp.lng}`).join('|');
    
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/create_route`,
                {
                    startPoint: `${startPoint.lat},${startPoint.lng}`,
                    endPoint: `${endPoint.lat},${endPoint.lng}`,
                    waypoints: formattedWaypoints,
                    orderIds: orderIds,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            if (response.data.message !== 'Arrival times updated successfully') {
                console.error('Google Maps API response error:', response.data);
                throw new Error('Failed to create route with Google Maps API');
            }
    
            console.log('Full response:', response.data); // Add this line to debug response data
            return response.data.routes ? response.data.routes[0] : null; // Ensure routes[0] is what you need
        } catch (error) {
            console.error('Error creating route:', error);
            throw error;
        }
    };

    if (selectedOrder) {
        return <OrderDriver order={selectedOrder} onBack={() => setSelectedOrder(null)} onBackClick={onBackClick} />;
    }

    return (
        <div style={{ maxHeight: '700px', overflowY: 'auto' }}>
            <ButtonBase onClick={() => { onBackClick() }} style={{ padding: '20px', color: 'black' }}>
                <ArrowBackIcon />
            </ButtonBase>
            <IconButton
                style={{
                    position: "absolute",
                    top: "20px",
                    right: 30,
                    zIndex: 1000,
                    backgroundColor: "white",
                    border: "2px solid black",
                    borderRadius: "50%",
                    color: "black",
                    marginBottom: "10px",
                    transform: "scale(0.9)",
                }}
                onClick={handlePickupRoutes}
            >
                <PlayCircleIcon />
            </IconButton>
            <IconButton
                style={{
                    position: "absolute",
                    top: "20px",
                    right: 85,
                    zIndex: 1000,
                    backgroundColor: "white",
                    border: "2px solid black",
                    borderRadius: "50%",
                    color: "black",
                    marginBottom: "10px",
                    transform: "scale(0.9)",
                }}
                onClick={handlePickupRoutesOptimized}
            >
                <InsightsIcon />
            </IconButton>
            <h1 style={{ color: 'black', fontSize: '2em', margin: '20px' }}>Recojos</h1>
            <List>
                {orderPoints.sort((a, b) => a.sequence - b.sequence).map((order, index) => (
                    <ButtonBase
                        onClick={() => !order.is_submitted && setSelectedOrder(order)}
                        key={index}
                        disabled={order.is_submitted} // Disable if order is already submitted
                    >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <SupportAgentIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<span style={{ color: 'black' }}>Order {order.id} Punto {order.sequence}</span>}
                                secondary={`Customer: ${order.customer_name}, Status: ${order.status}`}
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </ButtonBase>
                ))}
            </List>
        </div>
    );
}

export default OrderList;
