import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./components/App";
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


const injectGoogleMapsScript = () => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  if (!apiKey) {
      console.error("Google Maps API Key is missing. Please check your .env file.");
      return;
  }

  // Check if the Google Maps script is already added
  if (document.querySelector(`script[src*="maps.googleapis.com"]`)) {
      console.log("Google Maps script already loaded.");
      return;
  }

  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`;
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
  console.log("Google Maps API script injected.");
};

// Inject the Google Maps script
injectGoogleMapsScript();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
